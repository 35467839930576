import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { BsCheck2 } from "react-icons/bs";
import anime from "../utils/anime";

interface IProps {}

const Service2Container = (props: IProps) => {
  const list = [
    {
      title: "Үйлчилгээгээ сонгох",
      desc: "Айл гэр эсвэл албан байгууллагын цэвэрлэгээнүүдээс үйлчилгээгээ сонгоорой.",
    },
    {
      title: "Захиалгаа хийх",
      desc: "Таны захиалга өгсөн хаягт цагтаа цэвэрлэгээний хэрэгсэлтэйгээ очиж цэвэрлэгээгээ гүйцэтгэнэ.",
    },
    {
      title: "Давтан үйлчлүүлэх",
      desc: "Таны амьдардаг орчныг тав тухтай цэвэрхэн байлгахад бид танд тусалъя.",
    },
  ];

  const priceList = [
    {
      title: "Айл өрхийн цэвэрлэгээ",
      duration: "1 удаа",
      price: "1м2 нь ₮4,000",
      items: ["Гал тогоо", "Шал", "Хана", "Цонх"],
    },
    {
      title: "Оффис, албан байгууллага",
      duration: "1 удаа",
      price: "1м2 нь ₮4,000 - ₮5,000",
      items: ["Ширээ", "Сандал", "Нойл", "Шал"],
    },
  ];

  return (
    <div className="relative overflow-hidden">
      <div className="mx-auto relative max-w-7xl px-8">
        <div className="absolute hidden lg:block top-0 -right-1/2 lg:right-[2rem] bottom-0">
          <StaticImage
            src="../images/v2/cleaning_2.png"
            alt={"Urtuu"}
            placeholder="none"
            quality={100}
            layout="fixed"
            height={578}
          />
        </div>

        <div
          className="absolute top-0  hidden lg:block left-0 bottom-0 h-[578px] w-[150%] lg:w-[120%] right-0"
          style={{
            background:
              "linear-gradient(90deg, #FFFFFF 15.26%, #FFFFFF 35.56%, rgba(255, 255, 255, 0) 48.36%)",
          }}
        ></div>
        <div className="flex flex-col md:flex-row lg:min-h-[600px] items-center w-full relative">
          <div className="w-ful flex flex-col justify-center">
            <div className="block lg:hidden relative">
              <StaticImage
                src="../images/v2/cleaning_2.png"
                alt={"Urtuu"}
                placeholder="none"
                quality={100}
              />
            </div>
            <div className="text-left relative flex-col justify-center items-start">
              <div
                className="font-bold my-5 lg:my-10 text-[21px] lg:text-[38px] relative  inline-block uppercase mb-5"
                {...anime("fade-right", 600)}
              >
                цэвэрлэгээ
              </div>
              <p
                className="text-[18px] lg:text-[24px] text-justify lg:text-left p-0 w-full mb-2 text-[#1E1E1E] lg:w-1/2"
                {...anime("fade-right", 600, 300)}
              >
                Айл гэр, оффисын цэвэрлэгээ үйлчилгээг нэг удаа эсвэл тодорхой
                давтамжтайгаар хийж гүйцэтгэнэ. Олон улсын стандартын шаардлага
                хангасан ариутгал, халдваргүйжүүлэлтийн бүтээгдэхүүн,
                зориулалтын бодис уусмал ашиглана. Түүнчлэн үйлчлүүлэгчийн
                хүсэлтийн дагуу зөвхөн органик саван, ариутгалын бодис хэрэглэж
                болно.
              </p>
              {/* <button
                className="h-[85px] text-[21px] px-10 font-bold text-c1 items-center justify-center flex border-[2px] rounded-full border-c1"
                {...anime("fade-right", 600, 600)}
                onClick={() => {}}
              >
                Захиалах
              </button> */}
            </div>
          </div>
        </div>

        <h1 className=" text-[21px] lg:text-[38px] font-bold uppercase text-center m-5 mb-10">
          Үйлчилгээ авах дараалал
        </h1>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-10">
          {list?.map((l, i) => (
            <div
              {...anime("fade-up", 500, 300 * i)}
              key={`f-lis-${i}`}
              className="bg-[#F8F8F8] rounded-[20px] flex flex-col  space-y-4 justify-center items-center p-10"
            >
              <div className="w-[100px] h-[100px] text-5xl font-bold bg-c1 rounded-full flex items-center justify-center text-white">
                {i + 1}
              </div>
              <h3 className="font-bold text-[21px] text-center">{l.title}</h3>
              <p className="text-[#1E1E1E] text-center text-[18px]">{l.desc}</p>
            </div>
          ))}
        </div>

        <div className="flex justify-center items-center my-20 space-x-10">
          <a href="https://apps.apple.com/mn/app/urtuu/id6446311351">
            <StaticImage
              src="../images/appstore.png"
              alt={"Urtuu App - App Store"}
              placeholder="none"
              height={68}
              quality={100}
            />
          </a>
          <a href="https://play.google.com/store/apps/details?id=mn.urtuu.app">
            <StaticImage
              src="../images/playstore.png"
              alt={"Urtuu App - Play Store"}
              placeholder="none"
              height={68}
              quality={100}
            />
          </a>
        </div>
      </div>

      <div className="text-center mb-10 mt-28">
        <StaticImage src="../images/v2/crystal.png" quality={100} alt="" height={150} />
      </div>

      <div className="bg-c2 py-[50px]">
        <div className="mx-auto max-w-7xl px-8">
          <div className="grid items-center grid-cols-1 lg:grid-cols-2 grid-rows-1 w-full mx-auto lg:w-[80%]">
            <div className="overflow-hidden rounded-tl-[150px] s-fkr">
              <StaticImage
                src="../images/v2/cleaning_1.png"
                alt={"Urtuu"}
                placeholder="none"
                quality={100}
                // layout="fixed"
                // height={400}
                layout="fullWidth"
              />
            </div>
            <div className="p-10">
              <h1 className="text-[21px] lg:text-[38px] font-bold leading-10 mb-5 uppercase">
                Цэвэрлэгээний үйлчилгээ үзүүлэгчид
              </h1>
              <p className="text-[18px] leading-[40px]">
                Цэвэрлэгээний болон үйлчилгээний стандартын дагуу ажиллаж
                хэвшсэн, найдвартай үйлчилгээ үзүүлэгчид танд соёлтой үйлчилнэ.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-7xl mx-auto px-8 mb-20">
        <h1 className="text-[21px] lg:text-[38px] font-bold uppercase text-center my-20">
          Үнийн мэдээлэл
        </h1>

        <div className="flex flex-col lg:flex-row w-full justify-center mx-auto gap-10">
          {priceList?.map((p, i) => (
            <div className="bg-white shadow-xl lg:w-1/3 p-10 flex flex-col items-center border border-gray-100 rounded-xl">
              <StaticImage
                src="../images/f8.png"
                alt={"Urtuu"}
                placeholder="none"
                quality={100}
                // layout="fixed"
                height={85}
                layout="fixed"
              />
              <div className="w-full pt-10">
                <span className="text-[18px]">{p.duration}</span>
                <h3 className="font-bold text-[21px] text-left leading-[30px] my-2">
                  {p.title}
                </h3>
                <span className="text-[18px]">{p?.price}</span>
                <ul className="mt-5 space-y-6">
                  {p?.items?.map((_, i) => (
                    <li
                      className="flex items-center space-x-2 mb-2 text-[18px]"
                      key={`b1-${i}`}
                    >
                      <BsCheck2 size={21} /> <span>{_}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Service2Container;
