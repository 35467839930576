import * as React from "react";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import Service2ontainer from "../containers/Service2";

interface IProps {}

const Service2 = (props: IProps) => {
  return (
    <Layout>
      <Service2ontainer />
    </Layout>
  );
};

export default Service2;

export const Head = () => (
  <SEO title={`Цэвэрлэгээ`} description={`Цэвэрлэгээ`} />
);
